.table_section tr {
  border: 1px solid black;
  text-align: center;
  font-weight: 600;
}

.table_section td {
  min-width: 30px !important;
  border: 1px solid black !important;
}

.table_section th {
  background-color: #a5c9f0;
  text-align: center !important;
  font-weight: 600;
  color: #000000;
  border-bottom: 1px solid #000000 !important;
}

.table_section .holiday_cell {
  color: red;
  text-orientation: inherit;
  position: relative;
  writing-mode: vertical-rl !important;
  text-orientation: mixed;
}

.holiday_cell_wrapper {
  position: relative;
}

.holiday_cell_wrapper .holiday_desc {
  position: absolute;
  font-size: x-small;
  bottom: -22px !important;
  left: -12px;
  width: 100px;
  color: #5c5959da;
}

.light_green_boxes {
  background-color: #a9d499;
}

.light_purple_boxes {
  background-color: #ccc0d9;
}

.light_pink_boxes {
  background-color: #fabf8f;
}

.light_yellow_boxes {
  background-color: #f9e49c;
}

.white_boxes {
  background-color: #ffffff;
}

.green_boxes {
  background-color: #0dab5d;
}

.blue_boxes {
  background-color: #1cb1ee;
}

.yellow_boxes {
  background-color: #f8ed18;
}
